<template>
  <div class="container">
    <div class="row f-center mt-4">
      <div class="col-md-7">
        <!-- <div class="w-100 position-relative">
          <i class="las la-angle-right c-p h3" @click="scroll(130)"></i>
          <i class="las la-angle-left c-p h3" @click="scroll(-130)"></i>
        </div>
        <div class="w-100 tags mb-3" ref="boxSlider">
          <div class="tag f-center c-p" :class="{'active-teg': item.text === text}" @click="searchTag(item.text)"
            v-for="item in tags" :key="item.id" :style="{minWidth: 100 / 5 + '%'}">
            <i class="h6 m-0" :class="item.icon"></i>
            <h6 class="m-0 font-weight-light">{{item.text}}</h6>
          </div>
        </div> -->
        <div class="w-100 wrap-inp">
          <input :value='text' @input='evt=>text=evt.target.value' placeholder="חיפוש מוצר (לפי שם, סוג, יקב, וכו')" type="text" />
          <div class="clean-text fw-light f-center pe-3 ps-3 c-p" @click="text = ''">ניקוי</div>
        </div>
        <div class="w-100 mt-3" v-if="text.length > 1">
          <h6 class="m-0 font-weight-light">נמצאו {{searchArr.length}} מוצרים</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  // import aInput from "@/components/aInput.vue";

  export default {
    name: "searchInput",
    components: {
      // aInput
    },
    props: {
      items: Array,
      searchArr: Array
    },
    data() {
      return {
        text: ""
      };
    },
    methods: {
      search() {
        let arr = [];
        if (this.text.length > 1) {
        for (let i in this.items) {
          for (let x in this.items[i]) {
            if (this.items[i][x].toString().includes(this.text) && +this.items[i].publish) {
              arr.push(this.items[i]);
              break;
            }
          }
        }
        } else {
          arr = [];
        }
        this.$emit("passSearchArr", arr);
      }
    },
    // computed: {
    //   courses() {
    //     return this.$store.state.courses;
    //   },
    //   actualCourses() {
    //     return this.$store.getters.courses;
    //   },
    //   clearSearch() {
    //     return this.$store.state.clearSearch;
    //   }
    // },
    watch: {
      text: function () {
        this.search()
      }
    }
  };
</script>

<style scoped>
  .wrap-inp {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
  }

  input {
    width: 100%;
    border-radius: 20px;
    padding: 5px 20px;
    border: solid 2px var(--main-text-color);
  }

  .clean-text {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--main-color);
    color: #fff;
  }

  @media (max-width: 767.98px) {

    .clean-text {
      font-size: 14px;
    }

  }
</style>