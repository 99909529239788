<template>
  <div class="row">
    <div class="col">
      <topSection />
    </div>
  </div>
  <searchInput @passSearchArr="searchArr = $event" :items="products" :searchArr="searchArr" />
  <div class="container">
    <div class="row mt-2">
      <boxProduct v-for="item in searchArr.length? searchArr : products" :key="item.id" :item="item" />
    </div>
  </div>

</template>

<script>
  // @ is an alias to /src
  import topSection from '@/components/topSection.vue'
  import boxProduct from '@/components/boxProduct.vue'
  import searchInput from '@/components/searchInput.vue'

  export default {
    name: 'Home',
    components: {
      topSection,
      boxProduct,
      searchInput
    },
    data() {
      return {
        searchArr: []
      }
    },
    computed: {
      products() {
        return this.$store.state.products;
      }
    }
  }
</script>