<template>
  <section class="row row-top">
    <div class="b-g h-100 w-100 img-cover"></div>
    <div class="col">
      <div class="container">
        <div class="row pb-3 pt-3">
          <div class="col">
            <h1>חנות היינות של רפאל</h1>
            <h2>לא רק יין. לא רק מהשומרון.</h2>
            <img class="grapes mt-4 desktop" src="@/assets/ass2.png" />
            <!-- <div class="p-2 mess">משלוח עד הבית ב-18 שקלים בלבד. בקניה של 350 המשלוח על חשבוני</div> -->
            <div class="p-2 mess">יש משלוחים עד הבית, ללא תוספת תשלום. המשלוח יגיע אליכם עד 7 ימי עסקים (לרוב אפילו פחות מזה), בצורה שמורה ועם נהג יהודי. אם יש שאלות, בכיף.</div>
          </div>
        </div>
        <div class="row mt-5 desktop">
          <div class="col">
            <i class="las la-angle-down arrow-down"></i>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'topSection',
  components: {},
  props: {},
  data() {
    return {
      // icons: [{
      //     text: "ללמוד.",
      //     icon: "las la-school",
      //     id: 1
      //   },
      //   {
      //     text: "להתקדם.",
      //     icon: "las la-chart-line",
      //     id: 2
      //   },
      //   {
      //     text: "לצמוח.",
      //     icon: "lab la-pagelines",
      //     id: 3
      //   }
      // ]
    }
  }
}
</script>

<style scoped>
.row-top {
  position: relative;
  /* min-height: 400px; */
  padding: 100px 0px 40px 0px;
  text-align: center;
  color: #444;
  border-bottom: solid #444 3px;
  border-top: solid #444 3px;
}

.b-g {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url('https://cdn.pixabay.com/photo/2020/02/02/15/07/wine-4813260_960_720.jpg');
  opacity: 0.6;
  z-index: -1;
}

.box-icon {
  font-size: 55px;
  font-weight: 300;
}

h1 {
  font-weight: 800;
  font-size: 50px;
  text-shadow: 2px 2px 20px rgb(0, 0, 0);
  color: #fff;
}

h2 {
  font-weight: 300;
  font-size: 40px;
  text-shadow: 2px 2px 20px rgb(0, 0, 0);
  color: #ffffff;
}

.row-top img {
  filter: drop-shadow(2px 4px 6px #fff);
}

.grapes {
  width: 200px;
  opacity: 0.7;
}

i {
  font-size: 50px;
}

.arrow-down {
  position: relative;
  top: 11%;
  /* right: 42%; */
  /* font-size: 78px; */
  color: var(--main-text-color);
  /* text-shadow: 1px 1px 9px rgb(255, 255, 255); */
  z-index: 0;
  animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    top: 0%;
  }

  50% {
    top: 11%;
  }

  100% {
    top: 0%;
  }
}

.mess {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  font-size: 13px;
  border: var(--main-color) solid 2px;
  color: #fff;
}

@media (max-width: 767.98px) {
  .row-top {
    padding: 72px 0px 17px 0px;
  }

  .box-icon {
    font-size: 30px;
  }

  .mess {
    width: 100%;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  i {
    font-size: 40px;
  }
}
</style>
